import {
  ApiError,
  API_URL,
  PagedResponse,
} from "../utilities/api";
import { RoomModel } from "../models/locations.model";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { GroupRepresentation } from "../models/users.model";

export interface ApiHookData<T> {
  data: T;
  isLoading: boolean;
  error: ApiError;
}

export const useApiWithAccessToken = <T>(key: string): ApiHookData<T> => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const fetcher = useCallback(async (url: string) => {
    try {
      const res = await axios.get(url);
      setData(res.data);
    } catch (e) {
      console.error(e);
      setError(e as Error);
    }
  }, []);

  useEffect(() => {
    fetcher(key);
  }, [key, fetcher]);


  return {
    data,
    isLoading: !error && !data,
    error,
  };
};

export const useRooms = (): ApiHookData<PagedResponse<RoomModel>> => {
  return useApiWithAccessToken(`${API_URL}/rooms`);
};

export const useGroups = (): ApiHookData<PagedResponse<GroupRepresentation>> => {
  return useApiWithAccessToken(`${API_URL}/groups`);
};
