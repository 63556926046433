// These are the configurations needed to run this app.
interface ResaniConfig {
  // server
  api: {
    url: string;
  };
  usersApi: {
    url: string;
  };
  signalR: {
    url: string;
  };
  maintenanceApi: {
    url: string;
  };
  isBrowser: boolean;
  resaniEnvironment: "local" | "development" | "test" | "production";
  keycloak: {
    realm: string;
    authServerUrl: string;
    resource: string;
  };
  maintenance: {
    extendTimeMinutes: number;
    initialPauseTimeMinutes: number;
  };
  eventLogSpecs: {
    logSizeAlternatives: number[]
    events: EventSpec[];
  },
  screenUsers:{
    staticScreenGroupId: string,
    defaultScreenGroupIds: string[],
    screenOptions: ScreenTypeOption[]
  }
  resaniTenantIdentifier?: string;
}
export interface ScreenTypeOption {
  displayName:string
  firstName:string
  lastName:string
}
export interface EventSpec {
  baseEvent: boolean;
  name: string;
  color?: string;
  enumValue: number;
  filterable: boolean;
}
const config: ResaniConfig = { resaniTenantIdentifier: 'resani', ...(window as any).Env };

export default config;
