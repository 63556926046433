import { config } from "../config";

import qs from "qs";

export const API_URL = config.api.url;
export const MAINTENANCE_API_URL = config.maintenanceApi.url;

export interface IPaginationQuery {
  pageSize: number;
  page: number;
  toUrl: (key: string, baseUrl?: string) => string;
}

export interface SearchPagination {
  paginationQuery: IPaginationQuery;
  searchTerm: string | undefined;
}

export interface TenantSearchPagination extends SearchPagination {
  tenantId: string;
}

// export interface DeviceEventsPagination extends IPaginationQuery {
//   typesIncluded: EventTypeV1[] | null;
// }

export class PaginationQuery implements IPaginationQuery {
  pageSize = 10;
  page = 1;

  constructor(page: number = 1, pageSize: number = 10) {
    this.pageSize = pageSize;
    this.page = page;
  }

  toUrl(key: string, baseUrl: string = API_URL): string {
    const url = new URL(baseUrl + key);
    url.searchParams.set("pageSize", this.pageSize.toString());
    url.searchParams.set("page", this.page.toString());

    return url.toString();
  }
}

export interface PagedResponse<T> {
  items: T[];
  pageSize: number;
  currentPage: number;
  totalItems: number;
  totalPages: number;
  nextPage?: number;
}

export const emptyPagedResponse = <T>(): PagedResponse<T> => ({
  items: [],
  pageSize: 0,
  currentPage: 0,
  nextPage: 0,
  totalItems: 0,
  totalPages: 0,
});

export class ApiError extends Error {
  info: object;
  status: number;

  constructor(message: string, info: object, status: number) {
    super(message);
    this.info = info;
    this.status = status;
  }
}

export const onError = (error: ApiError, key: string) => {
  if (error.status !== 403 && error.status !== 404) {
    console.log("Fetch from api error", error);
  }
};


export const repeatedArrayFormat = function (params: any) {
  return qs.stringify(params, { arrayFormat: "repeat" });
};


export interface LocationQuery{
  latitude:number,
  longitude:number,
  orderByProximity:boolean,
}
